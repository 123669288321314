<template>
  <div class="box">
    <el-form :model="info" label-position="left" :rules="rules" ref="ruleForm" label-width="80px" size="small">
      <el-form-item label="选择门店" prop="store_id" v-if="shopList && shopList.length>0">
        <el-select v-model="info.store_id" placeholder="请选择门店" size="small" style="width: 100%">
          <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="类型" prop="c_type">-->
<!--        <el-radio-group v-model="info.c_type">-->
<!--          <el-radio :label="1">每日值班表</el-radio>-->
<!--          <el-radio :label="2">每周值班表</el-radio>-->
<!--          <el-radio :label="3">每月值班表</el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
      <el-form-item label="选择日期" prop="day" v-if="info.c_type==1">
        <el-date-picker class="mg-rt-10"
            v-model="info.start_date"
            readonly
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="会议周期" prop="week" v-if="info.c_type==2">
        <el-date-picker
            v-model="info.start_date"
            type="week"
            readonly
            :picker-options="{firstDayOfWeek:1}"
            format="yyyy 第 WW 周"
            placeholder="选择周">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="选择日期" prop="month" v-if="info.c_type==3">
        <el-date-picker
            v-model="info.start_date"
            type="month"
            readonly
            placeholder="选择月">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import util from "@/utils/util";
export default {
  data() {
    return {
      info: {
        store_id: '',
        c_type: '',
        start_date: '',
        week: '',
        month: ''
      },
      rules: {
        store_id: [
          {required: true, message: '请选择门店', trigger: 'blur'}
        ],
        c_type: [
          {required: true, message: '请选择类型', trigger: 'blur'}
        ],
        day: [
          {required: true, message: '请选择日期', trigger: 'blur'}
        ],
        week: [
          {required: true, message: '请选择日期', trigger: 'blur'}
        ],
        month: [
          {required: true, message: '请选择日期', trigger: 'blur'}
        ],
      }
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  props: {
    shopList: Array,
    weekInfo: Object
  },
  watch: {
    weekInfo: {
      handler: function(newVal, oldV) {
        this.info = newVal
        console.log(this.info)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    submitForm(formName) {
      this.$router.push({name: 'operate_storefour_show', query: {id: this.info.id}})
      // var _this = this
      // this.$refs['ruleForm'].validate((valid) => {
      //   if (valid) {
      //     var param = {
      //       store_id: _this.info.store_id,
      //       c_type: _this.info.c_type
      //     }
      //     if(param.c_type==1) {
      //       param.week = util.formatDate(_this.info.day)
      //     } else if(param.c_type==2) {
      //       var week = new Date(util.formatDate(_this.info.week))
      //       week.setDate(week.getDate() - 1)
      //       param.week = util.formatDate(week)
      //     } else if(param.c_type==3) {
      //       param.week = util.formatDate(_this.info.month).substring(0,7)
      //     }
      //     this.$api.operate.storefourAdd(param,function (res) {
      //       if(res.errcode == 0) {
      //         _this.success(res.errmsg)
      //         _this.$emit('callFunc');
      //       } else {
      //         _this.fail(res.errmsg)
      //       }
      //     })
      //   } else {
      //     return false;
      //   }
      // });
    },
  }
};
</script>
<style scoped>
</style>
